@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */

* {
    font-family: 'DM Sans', sans-serif;
}

/* mapbox */
.mapboxgl-ctrl-logo {
    display: none !important;
}
.mapboxgl-ctrl-group {
    box-shadow: none !important;
    @apply border-input border;
}

@layer base {
    :root {
        --background: 44 56% 92%;
        --foreground: 240 10% 3.9%;
        --card: 0 0% 100%;
        --card-foreground: 240 10% 3.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 240 10% 3.9%;
        --primary: 240 5.9% 10%;
        --primary-foreground: 0 0% 98%;
        --secondary: 240 4.8% 95.9%;
        --secondary-foreground: 240 5.9% 10%;
        --muted: 240 4.8% 95.9%;
        --muted-foreground: 240 3.8% 46.1%;
        --accent: 240 4.8% 95.9%;
        --accent-foreground: 240 5.9% 10%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 0 0% 98%;
        --border: 240 5.9% 90%;
        --input: 240 5.9% 90%;
        --ring: 240 5.9% 10%;
        --radius: 0.5rem;
        /* other */
        --message: 221.2 83.2% 53.3%;
        --message-foreground: 210 40% 98%;
        --chat-header: 0 0% 100%;
        --chat-content: 0, 0%, 100%, 0;
        --chat-footer: 0 0% 100%;
    }

    .dark {
        --background: 240 10% 3.9%;
        --foreground: 0 0% 98%;
        --card: 240 10% 3.9%;
        --card-foreground: 0 0% 98%;
        --popover: 240 10% 3.9%;
        --popover-foreground: 0 0% 98%;
        --primary: 0 0% 98%;
        --primary-foreground: 240 5.9% 10%;
        --secondary: 240 3.7% 15.9%;
        --secondary-foreground: 0 0% 98%;
        --muted: 240 3.7% 15.9%;
        --muted-foreground: 240 5% 64.9%;
        --accent: 240 3.7% 15.9%;
        --accent-foreground: 0 0% 98%;
        --destructive: 0 85% 50%;
        --destructive-foreground: 0 0% 98%;
        --border: 240 3.7% 15.9%;
        --input: 240 3.7% 15.9%;
        --ring: 240 4.9% 83.9%;
        /* other */
        --message: 217.2 91.2% 59.8%;
        --message-foreground: 222.2 47.4% 11.2%;
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }
}

@layer utilities {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

.ul_pass {
    list-style: none;
    margin-top: 5px;
    padding-left: 0;
}
.ul_pass li {
    line-height: 1;
}
.ul_pass li .text_sm_dop {
    display: inline-block;
    padding-left: 5px;
}
.ul_pass li.li_done:before {
    color: #06273f;
    content: '✓';
    display: inline-block;
    font-size: 16px;
}
.ul_pass li.li_no:before {
    color: #fc1111;
    content: '✕';
    display: inline-block;
    font-size: 16px;
}
.ul_pass li.li_no .text_sm_dop {
    color: #fc1111;
}
